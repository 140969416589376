import React, { useState, useEffect, useRef } from 'react';
import moment from "moment";
import Amplify, { Storage } from 'aws-amplify';
import { SendGridRequestURL, sendGridAxiosConfig } from './sendGridConfig';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { Button } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import TablePagination from '@material-ui/core/TablePagination';
import { IconButton, withStyles } from "@material-ui/core";
import Axios from 'axios';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useSelector, useDispatch } from 'react-redux';
import { toggleLoader } from '../../../store/slice/notificationSlice';
import format from "date-fns/format";
import { getDateInUTC } from '../../../helper/utils';
import TemplateViewModal from './TemplateViewModal';
import {downloadBlob} from '../../../helper/utils'

import {
    MuiPickersUtilsProvider,
    DateTimePicker
} from '@material-ui/pickers';
import { compareValues } from '../../../helper/utils';
import ConfirmBox from '../../Commonscreens/ConfirmBox';
import AlertDialog from '../../Commonscreens/AlertDialog';
import EmailCalendarMenu from './EmailCalendarMenu';
import EmailJobStatusTable from './EmailJobStatusTable'

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});



export default function EmailHistoryView() {
    const classes = useStyles();
    const dispatch = useDispatch()
    const selectedEmailJob = useRef('')
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const [selectedDate, handleDateChange] = useState(new Date());
    const [showDatePicker, toggleDatePicker] = useState(false);
    const [emailJobsHistory, setEmailJobs] = useState([]);
    const [activeSort, setActiveSort] = useState('createdTime');
    const [order, setOrder] = useState('asc');
    const [confirmationBoxDetails, setConfirmBoxDetails] = useState({
        message: '',
        open: false,
        handleClose: () => { },
        accept: () => { }
    });

    const [alertBoxDetails, setAlertDetails] = useState({
        open: false,
        message: ''
    });

    const [anchorEl, setAnchorEl] = React.useState(null);

    const [showTemplate, toggleTemplateView] = useState(false);
    const seletectedTemplateId = useRef('');
    const [showJobStats, toggleJobStats] = useState(false);
    const [emailJobStats, setEmailJobStats] = useState([]);

    const menuOnClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const menuOnClose = () => {
        setAnchorEl(null);
    };


    const handleChangePage = (event, newPage) => {
        console.log('newpage...', newPage)
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        getEmailJobs()
    }, []);

    const getAxiosConfig = () => {
        return {
            headers: {
                Authorization: sessionStorage.getItem('csrtoken')
            }
        }
    }
    const getEmailJobs = () => {
        dispatch(toggleLoader(true));
        Axios.get(process.env.REACT_APP_csrBaseUrl + 'csrportal/getnotifications', getAxiosConfig()).then((resp) => {
            if (resp && resp.data && resp.data.length > 0) {
                setEmailJobs(resp.data.reverse());
            }

            dispatch(toggleLoader(false));

        }).catch((err) => {
            console.log(err, 'failed to load email jobs');
            dispatch(toggleLoader(false));
        })

    }

    const getStatusFontColor = (status) => {
        switch (status) {
            case 'SCHEDULED':
                return 'orange'
            case 'EXCECUTED':
                return 'green'
            case 'FAILED':
                return 'red'
            default:
                return 'black'
        }
    }

    const createSortHandler = (type) => {

        console.log(type);
        let sortedList = emailJobsHistory.slice(0);

        let _order = order == 'asc' ? 'desc' : 'asc'; toString()
        if (activeSort != type) {
            _order = 'asc';
        }
        setOrder(_order);
        setActiveSort(type)

        sortedList.sort(compareValues(type, _order));
        setEmailJobs(sortedList);
        setPage(0);
        // console.log(sortedList)
    }

    const confirmRemove = (item) => {
        setConfirmBoxDetails({
            message: 'Are u sure want to remove the item?',
            open: true,
            handleClose: () => {
                setConfirmBoxDetails({ ...confirmationBoxDetails, open: false })
            },
            accept: () => {
                setConfirmBoxDetails({ ...confirmationBoxDetails, open: false })
                removeEmailJob(item)
            }
        })
    }

    const removeEmailJob = (item) => {

        let indexOfItemToRemove = -1;

        for (let i = 0; i < emailJobsHistory.length; i++) {
            if (emailJobsHistory[i].jobId == item.jobId) {
                indexOfItemToRemove = i;
                break;
            }
        }
        console.log('::indexOfItemToRemove::', indexOfItemToRemove)
        let emailJob = emailJobsHistory[indexOfItemToRemove];
        dispatch(toggleLoader(true));
        let request = {
            "jobId": emailJob.jobId,
            "createdBy": emailJob.createdBy
        }
        Axios.post(process.env.REACT_APP_csrBaseUrl + 'csrportal/deletenotification', request, getAxiosConfig()).then((resp) => {

            dispatch(toggleLoader(false));
            setAlertDetails({
                open: true,
                message: resp.data
            });

            let updatedEMailJobsHistory = [...emailJobsHistory];
            updatedEMailJobsHistory.splice(indexOfItemToRemove, 1);
            setEmailJobs(updatedEMailJobsHistory);

        }).catch((err) => {
            console.log(err, 'failed to remove email jobs');
            dispatch(toggleLoader(false));
        })

    }

    const retriggerEmail = (item, index) => {
        selectedEmailJob.current = { ...item, index };

        if (new Date(item.triggerTime).getTime() <= new Date().getTime()) {
            handleDateChange(new Date())
        } else {
            handleDateChange(new Date(item.triggerTime))
        }

        setTimeout(() => {
            toggleDatePicker(true)
        }, 10);
    }

    const onUpdateTriggerTime = (date) => {
        console.log('selectedEmailJob::', selectedEmailJob.current)
        console.log('date::', date)
        toggleDatePicker(false);
        dispatch(toggleLoader(true));
        let datInMilliSecs = date.getTime();
        let dateInUTC = getDateInUTC(datInMilliSecs);
        let dateInUTCinMilliseconds = getDateInUTC(date.getTime(), true);
        let triggerDateAndTime = moment(dateInUTC).format('YYYY-MM-DD hh:mm:ss');


        let item = selectedEmailJob.current;
        let request = {
            "jobId": item.jobId,
            "triggerTime": triggerDateAndTime,
            "jobName": item.jobName,
            "createdBy": item.createdBy
        }

        Axios.post(process.env.REACT_APP_csrBaseUrl + 'csrportal/updatenotification', request, getAxiosConfig()).then((resp) => {

            dispatch(toggleLoader(false));
            setAlertDetails({
                open: true,
                message: resp.data
            });

            let updatedEmailJobsHistory = [...emailJobsHistory];
            updatedEmailJobsHistory[selectedEmailJob.current.index]['triggerTime'] = datInMilliSecs;
            setEmailJobs(updatedEmailJobsHistory);

        }).catch((err) => {
            console.log(err, 'failed to update email jobs');
            dispatch(toggleLoader(false));
        })

    }


    const viewTemplate = (templateId) => {
        // setTemplateId(templateId)
        seletectedTemplateId.current = templateId;
        toggleTemplateView(true)
    }

    const renderDayBasedOnSelectedDate = (day, selected, dayInCurrentMonth, dayComponent) => {
        console.log('::selectedDate::', selectedDate.getTime())
        let item = selectedEmailJob.current;
        if (new Date(item.triggerTime).getTime() <= new Date().getTime()) {
            if (day.toDateString() == new Date(item.triggerTime).toDateString()) {
                return (
                    <div style={{
                        background: 'rgb(184 125 220)',
                        borderRadius: '20px'
                    }}>
                        {dayComponent}
                    </div>
                );
            }

        }

        return dayComponent
    }

    const downloadMemberList = async (emailJob) => {

        let key = emailJob.dataSetName;
        dispatch(toggleLoader(true));
        const result = await Storage.get(key, { download: true, customPrefix: { public: '' } });
        downloadBlob(result.Body, key);
        dispatch(toggleLoader(false));
    }


    const viewJobStatus = async (emailJob) => {
        let sampleQuery = 'subject="Universal HealthShare - Sales Receipt"AND last_event_time BETWEEN TIMESTAMP "2021-09-15T18:30:00.000Z" AND TIMESTAMP "2021-09-17T07:04:07.503Z"AND from_email="broker@ahpahealth.org"'
        let triggerTime = new Date(emailJob.triggerTime);
        triggerTime.setHours(triggerTime.getHours() - 1);
        let startTime =  triggerTime.toJSON();
        // let endTime = new Date().toJSON();
        let endTime = new Date(emailJob.triggerTime);
        endTime.setHours(endTime.getHours() + 1);
        endTime = endTime.toJSON();

        let statsQuery = `subject="${emailJob.emailSubject}"AND last_event_time BETWEEN TIMESTAMP "${startTime}" AND TIMESTAMP "${endTime}"AND from_email="${emailJob.fromId}"`;
        statsQuery = encodeURI(statsQuery);

        let query = `limit=1000&query=${statsQuery}`;
        console.log(query);

        dispatch(toggleLoader(true));

        await Axios.get(SendGridRequestURL + 'messages?' + query, sendGridAxiosConfig).then((resp) => {
            toggleJobStats(true)
            debugger
            let messages = resp.data.messages || []
            setEmailJobStats(messages)
            dispatch(toggleLoader(false));
        }).catch((err) => {
            console.log(err, 'failed to load stats')
            dispatch(toggleLoader(false));
            setAlertDetails({
                open: true,
                message: 'Failed to load Stats!'
            });
        })
    }


    const emptyRows = rowsPerPage - Math.min(rowsPerPage, emailJobsHistory.length - page * rowsPerPage);
    // return <CustomTable />

    // console.log(":::   eselectedDate:", selectedDate)
    return (
        <div style={{
            marginTop: '20px',
            padding: '0 20px'
        }}>
            {/* <p>Email History</p> */}
            <div>
                <TableContainer component={Paper}>
                    <Table stickyHeader className={classes.table} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow style={{
                                background: '#d0dff1'
                            }}>
                                <TableCell >
                                    <TableSortLabel
                                        active={activeSort == 'createdTime'}
                                        direction={order}
                                        onClick={() => createSortHandler('createdTime')}
                                    >Created Date</TableSortLabel>
                                </TableCell>
                                <TableCell>Job Name</TableCell>
                                <TableCell>Subject</TableCell>
                                <TableCell >Created By</TableCell>
                                <TableCell align="right">
                                    <TableSortLabel
                                        active={activeSort == 'triggerTime'}
                                        direction={order}
                                        onClick={() => createSortHandler('triggerTime')}
                                    >Trigger Time </TableSortLabel>
                                </TableCell>

                                <TableCell align="right">

                                    <TableSortLabel
                                        active={activeSort == 'status'}
                                        direction={order}
                                        onClick={() => createSortHandler('status')}
                                    >Status</TableSortLabel>

                                </TableCell>
                                <TableCell align="right">Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                emailJobsHistory.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, indx) => {
                                    return (
                                        <TableRow key={indx}>
                                            <TableCell >  {

                                                item.createdTime ?
                                                    moment(new Date(item.createdTime)).format('YYYY-MM-DD hh:mm A')
                                                    : ' - '
                                            }</TableCell>
                                            <TableCell component="th" scope="row">
                                                {item.jobName || ' - '}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {item.emailSubject || ' - '}
                                            </TableCell>
                                            <TableCell >  {
                                                item.createdBy
                                            }</TableCell>
                                            <TableCell align="right">  {
                                                item.triggerTime ?
                                                    moment(new Date(item.triggerTime)).format('YYYY-MM-DD hh:mm A')
                                                    : ' - '

                                            }</TableCell>

                                            <TableCell align="right">
                                                <span style={{
                                                    color: getStatusFontColor(item.status),
                                                    fontWeight: 600
                                                }}>
                                                    {item.status}
                                                </span>

                                            </TableCell>
                                            <TableCell align="right">
                                                <div>
                                                    {/* <Button aria-controls="simple-menu" aria-haspopup="true"
                                                        variant="outlined"
                                                        color="primary"
                                                        startIcon={<ExpandMoreIcon />}
                                                        onClick={menuOnClick}>
                                                        More
                                                    </Button> */}
                                                    {/* <IconButton aria-label="delete" onClick={(event) => {
                                                        let updatedList = emailJobsHistory.slice(0);
                                                        updatedList[indx]['openMenu'] = true;
                                                        setEmailJobs(updatedList)
                                                        setAnchorEl(event.currentTarget);
                                                    }}>
                                                        <MoreVertIcon />
                                                    </IconButton> */}
                                                    <EmailCalendarMenu openMenu={item.openMenu} emailJob={item} anchorEl={anchorEl}
                                                        confirmRemove={confirmRemove}
                                                        retriggerEmail={retriggerEmail}
                                                        emailJobIndx={indx}
                                                        viewTemplate={viewTemplate}
                                                        viewJobStatus={viewJobStatus}
                                                        downloadMemberList={downloadMemberList}
                                                        closeMenu={() => {
                                                            // let updatedList = emailJobsHistory.slice(0);
                                                            // updatedList[indx]['openMenu'] = false;
                                                            // setEmailJobs(updatedList)
                                                            // setAnchorEl(null);
                                                        }} />






                                                </div>

                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 33 }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={emailJobsHistory.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />


            </div>

            <div style={{ display: 'none' }}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker
                        open={showDatePicker}
                        minDate={new Date()}

                        // invalidDateMessage={invalidDateMessage}
                        // clearable
                        style={{
                        }}
                        renderDay={renderDayBasedOnSelectedDate}
                        placeholder="mm/dd/yyyy"
                        margin="normal"
                        id="date-picker-dialog"
                        value={selectedDate}
                        onChange={(event) => {
                            console.log(event)
                            let currentDate = new Date();
                            currentDate.setHours(currentDate.getHours() + 2);

                            let newDate = event;
                            debugger
                            if (newDate >= currentDate) {
                                handleDateChange(event);
                                // toggleDatePicker(false)
                                // setInvalidDateMessage('')
                            } else {
                                handleDateChange(currentDate);
                                // setInvalidDateMessage('Time should be more 2 hours from now!')

                            }
                        }}
                        KeyboardButtonProps={{
                            'aria-label': 'string',
                        }}
                        disabled={false}
                        // readOnly={true}
                        onAccept={(event) => {
                            console.log(event)
                            let currentDate = new Date();
                            currentDate.setHours(currentDate.getHours() + 2);

                            let newDate = event;

                            if (newDate >= currentDate) {
                                onUpdateTriggerTime(event);
                                // toggleDatePicker(false)
                                // setInvalidDateMessage('')
                            } else {
                                setAlertDetails({
                                    open: true,
                                    message: 'Re-Trigger Time should be more than 2 hours from now! Please update accordingly.'
                                });


                                // onUpdateTriggerTime(currentDate);
                                // setInvalidDateMessage('Time should be more 2 hours from now!')

                            }
                        }}
                        // onAccept={onUpdateTriggerTime}
                        onClose={(date) => {
                            toggleDatePicker(false)
                            console.log('onClose::')
                        }}
                        onError={() => { }} />
                </MuiPickersUtilsProvider>
            </div>
            <ConfirmBox
                open={confirmationBoxDetails.open}
                message={confirmationBoxDetails.message}
                accept={confirmationBoxDetails.accept}
                handleClose={confirmationBoxDetails.handleClose}
            />

            <AlertDialog open={alertBoxDetails.open} messageText={alertBoxDetails.message} closeAlert={() => setAlertDetails({
                open: false,
                message: ''
            })
            } />

            {
                showTemplate &&
                <TemplateViewModal templateId={seletectedTemplateId.current} toggleTemplateView={toggleTemplateView}
                    toggleAlert={(value) => {
                        setAlertDetails({
                            open: value
                        })
                    }}
                    setMessageText={(message) => {
                        setAlertDetails({
                            message: message
                        })
                    }}
                />
            }
            {
                showJobStats &&
                <EmailJobStatusTable emailJobStats={emailJobStats} closeModal={() => {
                    toggleJobStats(false)
                }} />
            }

        </div>
    );
}