import React, { Component } from 'react';
export default class Footer extends Component {
    render() {
        return (
            <div className=" text-center Copyright-2020-Universal-Health-Fellowship-All" style={{marginTop:'-15px'}}>
                {window.copyrightText()}
            </div>
        )
    }
}

