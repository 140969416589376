import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import AccountCircle from '@material-ui/icons/AccountCircle';
import customStyle from '../../Assets/CSS/stylesheet_UHS'

const CustomTextField = withStyles(
    customStyle.fileTextField,
)(TextField);

class sampleFileTextField extends Component {
    x = {};
    constructor(props) {
        super(props);
        this.state = { value: '', errorText: this.props.helperMsg, isValid: false, requiredFlag: this.props.reqFlag }
    }

    componentDidMount() {
    }

    componentWillUnmount() {
        this.state.value = '';
    }


    onChange = (event) => {
        event.preventDefault();
        console.log('===== ', event);
        let txtVal = event.target.value;
        txtVal = event.target.value.trimLeft();
        if (txtVal !== "") {
            switch (this.props.fieldType) {
                case 'text':
                    {
                        if (txtVal.match(/^[a-zA-Z]*$/)) {
                            this.setState({ isValid: false, value: txtVal });
                            this.props.setChild(txtVal, true, this.props.parentDetails);
                            break;
                        } else {
                            this.setState({ errorText: this.props.errMsg, isValid: true, value: txtVal });
                            this.props.setChild(txtVal, false, this.props.parentDetails);
                            break;
                        }
                    }
                    case 'file':
                        {
                            if (txtVal.match(/^.+\.(xlsx|xls|csv)$/)) {
                                this.setState({ isValid: false, value: txtVal });
                                this.props.setChild(txtVal, true, this.props.parentDetails);
                                break;
                            } else {
                                this.setState({ errorText: this.props.errMsg, isValid: true, value: txtVal });
                                this.props.setChild(txtVal, false, this.props.parentDetails);
                                break;
                            }
                        }
            }
        } else {
            if (this.state.requiredFlag) {
                this.setState({ errorText: this.props.helperMsg, isValid: true, value: txtVal });
                this.props.setChild(txtVal, false, this.props.parentDetails);
            } else {
                this.setState({ errorText: '', isValid: false, value: txtVal });
                this.props.setChild(txtVal, true, this.props.parentDetails);
            }
        }
    }

    handlerCopy(e) {
        e.preventDefault();
    }

    render() {
        if (this.props.label === 'First Name') {
            this.x = {
                endAdornment: (
                    <InputAdornment position="end">
                        <AccountCircle />
                    </InputAdornment>
                ),
            }
        }
        return (
            <CustomTextField
                error={this.props.value === '' && this.state.isValid}
                label={this.props.label}
                name={this.props.name}
                variant="filled"
                autoComplete='off'
                value={this.props.value === '' && this.state.isValid ? this.state.value : this.props.value}
                style={this.props.style}
                helperText={(this.props.value === '' && this.state.isValid) || this.props.value === '' ? this.state.errorText : ''}
                onChange={this.props.onChange.bind(this)}
                disabled={this.props.disable}
                required={this.props.reqFlag}
                InputLabelProps={{ style: { color: (this.props.value === '' && this.state.isValid) ? '#FA1515' : '' } }}
                inputProps={{
                    maxLength: this.props.length,
                }}
                InputProps={this.x}
                type={this.props.fieldType}
                accept = {".csv"}
                
            />
        );
    }
}

export default sampleFileTextField;


