import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import ViewTransactionHistory from './ViewTransactionHistory'


const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: 0,
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography {...other} style={{
            padding: '10px'
        }}>
            <p style={{
                margin: 0,
                fontSize: '15px',
                fontWeight: 'bold'
            }}>{children}</p>
            {onClose ? (
                <IconButton  aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export default function AlertDialog(props) {
    const [open, setOpen] = React.useState(false);


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        props.handleClose()
    };



    // const classes = useStyles()
    return (
        <div>

            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={props.open} maxWidth={"lg"} fullWidth={true}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Transaction History
          </DialogTitle>
                <DialogContent dividers>
                    <ViewTransactionHistory globaLoader={props.toggleLoader} userData={props.memberDetails} toggleRegistrationStatus={handleClose} toggleAlertBox={props.toggleAlertBox} />
                </DialogContent>
                <DialogActions>

                </DialogActions>
            </Dialog>
        </div>
    );
}