const style = {
    textAlign : {
        textAlign: 'left',
        color: 'black',
        marginLeft: '0px',
        marginTop: '0px',
        fontFamily : 'Roboto, Arial, Helvetica, sans-serif',
        fontSize : '15px',
        fontWeight : 'bold'
    },
    textAlign1 : {
        color:'rgba(0, 0, 0, 0.87)',
        textAlign:'justify',
        wordSpacing:'-1px',
        fontFamily : 'Roboto, Arial, Helvetica, sans-serif',
        fontSize : '14px',
    },
    container:{
        display: 'flex',
      
     },
     helpText :{
        color: '#304d63',
        fontSize: '14px',
        lineHeight: '16px',
        textAlign: 'right',
        float: 'right',
    },
    textField: {
        width: '100%',
        marginTop: '0',
        marginBottom: '0',
        marginLeft: '0',
        borderRadius: '4px',
        // height:'60px'
    }, 
    textFieldBankName: {
        width: '128%',
        marginTop: '0',
        marginBottom: '0',
        marginLeft: '0',
        borderRadius: '4px'
    },
    textFieldDiv :{
        width: '50%',
        marginTop: '26px',
        marginRight: '25px'
    },
    textFieldDiv1 :{
        width: '16.4%',
        marginTop: '10px',
        marginRight: '25px',
    },
    dropdownDiv :{
        width: '33.3%',
        marginTop: '26px',
        marginRight: '25px'
    },
    dropdownDiv1 :{
        width: '30%',
        marginTop: '26px',
        marginRight: '25px'
    },
    crudIcon: {
        width: '40px',
        height: '40px',
    },
    w70 : {
        width : "70%",
        display: "inline-block"
    },

    w30 : {
        width : "30%",
        display: "inline-block",
        marginTop: '10px',
    },
    w30pay : {
        width : "30%",
        display: "inline-block",
        marginTop: '10px',
        position : 'relative',
      //  height:'50px'
    },


    addBackground: {
        background: 'rgb(241, 241, 241)',
        padding: '2px 5px',
        marginBottom: '5px',
        fontSize: '14px',
        display: 'flex'
    },
    removeBackground: {
        background: 'transparent',
        padding: '2px 5px',
        marginBottom: '5px',
        fontSize: '14px',
        display: 'flex'
    },
    payTitle: {
        width: '80%'
    },
    payAmount: {
        width: '20%',
        textAlign: 'right'
    },

    w100 : {
        width : "100%"
    },
    df : {
        display : 'flex'
    },
    planText: {
        width: '102%',
        fontSize: '14px',
        lineHeight: '30px',
        fontFamily : 'Roboto, Arial, Helvetica, sans-serif'
        // letterSpacing: '0.44px'
    },
    dropDown : {
        width: '100%',
        marginTop: '0',
        marginBottom: '0',
        marginLeft: '0',
        borderRadius: '4px',
        // height: '56px',
        WebkitHeight:'90px'
    },
    planTextBold : {
        width: '102%',
        fontSize: '14px',
        lineHeight: '30px',
        fontWeight: 'bold',
        letterSpacing: '0.44px',
        fontFamily : 'Roboto, Arial, Helvetica, sans-serif'
    },

    planTextCC: {
        width: '102%',
        fontSize: '12px',
        lineHeight: '24px',
        letterSpacing: '0.44px',
        position: 'absolute',
        bottom: '50px',
        top:'128px'
    },

    lastCh : {
        display: 'flex',
        width: '100%',
        height: '40px',
        borderBottom: '1px solid #e1e1e3',
        paddingTop:'9px',
        marginTop : '25px'
    },

    planPrice: {
        width: '30%',
        fontSize: '14px',
        lineHeight: '30px',
        letterSpacing: '0.44px',
        fontWeight: 'bold',
        color: '#19191d',
        fontFamily : 'Roboto, Arial, Helvetica, sans-serif'
    },
    planBox: {
        display: 'flex',
        width: '100%',
        height: '35px',
        borderBottom: '1px solid #e1e1e3',
        fontFamily : 'Roboto, Arial, Helvetica, sans-serif',
        fontSize : '14px'
        // paddingTop:'2px'
    }
}
export default style;