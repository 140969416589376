import React, { Component } from 'react';
import customStyle from '../../Assets/CSS/stylesheet_UHS';
import Grid from '@material-ui/core/Grid';
import Button from "@material-ui/core/Button";
import { Modal, Dropdown } from "react-bootstrap";
import { withStyles, createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Loader from "../Commonscreens/loader";
import axios from "axios";
import customecss from "../Screens/AgentCreation.css";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from '@material-ui/core/TextField';
import Sample from '../Commonscreens/sampleTextField';
import SampleFile from '../Commonscreens/SampleFileTextField';
import configurations from "../../configurations";
import customStyleUHS from '../../Assets/CSS/stylesheet';

import CommonDropDwn from './changePayment/CommonDropDwn';
import '../../Assets/CSS/window.css';
import { jsonToCSV } from 'react-papaparse';
import { fi } from 'date-fns/locale';



const CustomTextField = withStyles(theme => ({
    root: {
        '& .MuiFilledInput-root': {
            backgroundColor: '#f8f8f8',
            color: '#19191d',
            fontSize: '16px',
            lineHeight: '24px',
            textAlign: 'left',
            // height: '56px',
            borderColor: '#533278',
            '&:hover': {
                backgroundColor: '#f4f4f4',
                color: '#533278',
            },
            "&:hover:not($disabled):not($focused):not($error):before": {
                // hover
                borderBottom: '2px solid #533278'
            },

            '&.MuiFilledInput-underline:after': {
                borderBottom: '2px solid #533278'
            },
            '&.MuiFilledInput-underline.Mui-error:after': {
                //borderBottomColor: '#f44336'
                borderBottom: '2px solid #f44336',

            },
        },
        '&.MuiInputBase-formControl': {
            height: '56px',
        },

        '&.MuiSelect-selectMenu ': { paddingRight: '132px' },
        '& .Mui-focused': {

        },
        '&.MuiSelect-filled.MuiSelect-filled': { paddingRight: '132px', color: 'red' },
        '& .MuiInputLabel-filled': {
            wordBreak: 'normal',
            //   whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            // width: '101%',
            overflow: 'hidden'
        },

        '& label.Mui-focused': {
            color: '#533278',
            wordBreak: 'normal',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            width: 'auto',
            overflow: 'hidden'
        },

        '& p.MuiFormHelperText-contained': {
            margin: 0,
            fontSize: "12px",
            marginTop: '6px'
        }
    },
    select: { '&.MuiSelect-filled.MuiSelect-filled': { paddingRight: '132px', color: 'red' } },
}))(TextField);

const NextButton = withStyles(
    customStyle.doneBtn
)(Button);

const CustomButton = withStyles(
    customStyle.viewBtn
)(Button);

const GenBtn = withStyles(
    customStyleUHS.genrateBtn
)(Button);

class SentEmailTemplate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaderShow: false,
      msgModal: false,
      countryCode: "",
      refresh: false,
      userValid: true,
      channelName: "",
      program: "",
      planId: "",
      programsList: ["WellLife"],
      agentTypesList: ["Household", "Employer"],
      agentType: "",
      appFee: "",
      welcomePDF: "",
      templateId: "",
      fromId: "",
      subject: "",
      file: '',
    };
  }

  // componentDidMount() {
  //     this.getCountryCode();
  //     // this.getAllData();

  // }

  // getAllData = () => {
  //     fetch(configurations.agentBaseUrl + '/enrollment/getAllClient/')
  //         .then(response => response.json())
  //         .then(data => {
  //             this.setState({ List: data.response })
  //         }
  //         );

  // }

  // -----------------Country Code for Mobile no.------------

  // getCountryCode = () => {
  //     axios.get('https://ipapi.co/json/').then((response) => {
  //         if (response && response.data) {
  //             let data = response.data;
  //             this.setState({
  //                 countryCode: data.country_calling_code
  //             }, () => console.log(this.state.countryCode));
  //         }
  //     }).catch((error) => {
  //         console.log(error);
  //     });
  // }

  // -----------------Agent Registration ------------

  sentMailDetails = () => {
    this.setState({
      loaderShow: true,
      msgModal: false,
    });

    let data =  JSON.stringify({
      templateId: this.state.templateId,
      fromId: this.state.fromId,
      subject: this.state.subject,
      })

    const formData = new FormData();
    formData.append("file", this.state.file)
    formData.append("csvrequest", data);


 
    // let obj = {
      
    //   file: this.state.fileVal,
    // };

    axios
      .post('https://dev.fabric.carynhealth.com/api/v29/marketing/email-marketing',
        formData,
        {
          headers: {

            'Content-Type':'multipart/form-data'
            
          },
        }
      )
      .then((response) => {
        this.setState({
          loaderShow: false,
          msgModal: true,
          errMsg: "Start Sending Emails",
          refresh: true,
          code: response.data.code,
        });
      });
  };

  // --------------- OnChange Text data---------------------------

 



  setUserValue = (value, isValid, parentDetails) => {
    console.log("-----------------", parentDetails.name);
    console.log(value);
    if (isValid) {
      this.state[parentDetails.name] = value;
    } else {
      this.state[parentDetails.name] = "";
    }
    this.checkVal();
  };


  
changeHandler = (e) => {
    this.setState({
  
        file:e.target.files[0]
        
    }
    , (() => {
      this.checkVal()
    }))
    console.log("___file",this.state.file)
   
}


  // -------------Vallidation--------------------------

  checkVal() {
    if (this.state.templateId !== '' && this.state.subject !== '' && this.state.fromId !== '' && this.state.file !== '' ) {
        this.setState({ userValid: false })
    } else {
        this.setState({ userValid: true })
    }

}

  // ----------------Drop down value change-----------------------

  // changeData = (event) => {
  //   var data = this.state.List;
  //   var index = data.find(function (cData) {
  //     return cData.clientName == event.target.value;
  //   });
  //   this.setState({
  //     // associationId: index.association,
  //     clientId: index.clientId,
  //     clientName: event.target.value,
  //   });
  // };

  // ---------------Clear Data--------------------

  clearData = () => {
    this.setState({
      channelName: "",
      program: "",
      planId: "",
      userValid: true,
      errFname: "",
      agentType: "",
      appFee: "",
      welcomePDF: "",
      templateId:"",
      file:"",
      subject:"",
      fromId:"",
    });

    // window.location.reload()
  };

  handleOk = () => {
    if (this.state.code === 200) {
      this.setState({ msgModal: false, loaderShow: false }, () =>
        this.clearData()
      );
    } else {
      this.setState({ msgModal: false, loaderShow: false });
    }
  };
 

  handleBack = () => {
    this.props.onClick();
  };

  render() {
   
    return (
      <div style={{ marginLeft: "11%" }}>
        {/* style={{height:'100vh'}} */}
        {this.state.loaderShow ? <Loader></Loader> : ""}
        <div style={{ padding: "2%", width: "900px", marginTop: "0%" }}>
          {/* backgroundColor: 'rgb(191, 191, 191)' */}
          <Grid>
            <form id="form">
              <div style={customStyle.HomeContainer2}>
                {/* <div style={{ backgroundColor: 'rgb(51,175,176)', dispaly: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', width: '100%', padding: '15px', fontWeight: 'bold' }} >Create a Channel</div> */}

                <div style={customStyle.HomeMAinChild}>
                  <div style={customStyle.HomeTextContiner}>
                    <div style={{ width: "420px", margin: "10px" }}>
                      <Sample
                        setChild={this.setUserValue.bind(this)}
                        onPaste={this.handlePaste}
                        reqFlag={true}
                        name={"templateId"}
                        label={"Template ID"}
                        value={this.state.templateId}
                        disable={false}
                        style={customStyle.textFieldWrpAgent}
                        // length={35}
                        fieldType={"street"}
                        errMsg={"Enter valid Template id"}
                        parentDetails={{ name: "templateId" }}
                      ></Sample>
                    </div>
                  </div>

                  {/* ========================================= */}
                  <div style={customStyle.HomeTextContiner}>
                    <div style={{ width: "420px", margin: "10px" }}>
                      <Sample
                        setChild={this.setUserValue.bind(this)}
                        reqFlag={true}
                        name={"subject"}
                        label={"Alliance ID"}
                        value={this.state.subject}
                        disable={false}
                        style={customStyle.textFieldWrpAgent}
                        // length={25}
                        fieldType={"street"}
                        errMsg={"Enter valid Subject"}
                        parentDetails={{ name: "subject" }}
                      ></Sample>
                    </div>
                  </div>

                  <div style={customStyle.HomeTextContiner}>
                    <div style={{ width: "420px", margin: "10px" }}>
                      <Sample
                        setChild={this.setUserValue.bind(this)}
                        reqFlag={true}
                        name={"fromId"}
                        label={"From ID"}
                        value={this.state.fromId}
                        disable={false}
                        style={customStyle.textFieldWrpAgent}
                        // length={25}
                        fieldType={"email"}
                        errMsg={"Enter valid from id"}
                        parentDetails={{ name: "fromId" }}
                      ></Sample>
                    </div>
                  </div>

                  <div style={customStyle.HomeTextContiner}>
                    <div style={{ width: "420px", margin: "10px" }}>
                      <SampleFile
                        className="uploadBtn"
                        reqFlag={true}
                        name={"file"}
                        label={"Sender list file - Test,Actual"}
                        defaultValue={this.state.file}
                        disable={false}
                        style={customStyle.textFieldWrpAgent}
                        length={25}
                        fieldType={"file"}
                        errMsg={"Enter valid pdf type"}
                        parentDetails={{ name: "file" }}
                        accept = {".csv"}
                       onChange = {this.changeHandler.bind(this)}
                      ></SampleFile>
                    </div>
                  </div>

                  <div style={{ display: "flex", width: "100%" }}>
                    <div style={{ width: "100%", marginLeft: "10%" }}>
                      <NextButton
                        style={{
                          width: "96px",
                          height: "40px",
                          margin: "10px",
                        }}
                        onClick={this.clearData}
                      >
                        CANCEL
                      </NextButton>
                      <NextButton
                        style={{
                          width: "96px",
                          height: "40px",
                          margin: "10px",
                        }}
                        disabled={this.state.userValid}
                        onClick={this.sentMailDetails}
                      >
                        Submit
                      </NextButton>
                    </div>
                  </div>
                </div>
              </div>
            </form>

            {/*=================================== Message Model ======================================*/}

            <Modal
              size="md"
              show={this.state.msgModal}
              onHide={this.handleOk}
              style={{ marginTop: "10%" }}
              backdrop="static"
            >
              <Modal.Header style={customStyle.modal_header} closeButton>
                <Modal.Title>Message</Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ padding: "15px" }}>
                {this.state.loaderShow ? <Loader></Loader> : ""}

                <div style={customStyle.HomeContainer1}>
                  <div style={customStyle.HomeMAinChild}>
                    {this.state.errMsg}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <CustomButton
                  style={{ height: "40px" }}
                  onClick={this.handleOk}
                >
                  Ok
                </CustomButton>
              </Modal.Footer>
            </Modal>
          </Grid>
        </div>
      </div>
    );
  }
}

export default SentEmailTemplate;