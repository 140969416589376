import React, { useEffect } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import Header from './Commonscreens/Header';
import Footer from './Commonscreens/Footer';


const Layout = (props) => {

    let history = useHistory();
    let location = useLocation();

    console.log(location.pathname)
    useEffect(() => {

    })
    const goBack = () => {
        history.goBack();
    }
    return (
        <div>
            {/* <IdleSessionHandle /> */}
            <div className="window" style={{width: '100%'}}>
                <Header goBack={goBack} history={history} setUserLogin={props.setUserLogin} pathName={location.pathname} />
            </div>


            <div style={{ backgroundColor: 'rgb(234, 232, 219)' }}>
                <div className=" top-horizontal-margin text-center" style={{
                    padding: '0px 40px'
                }}  >
                    <div style={{ display: 'flex', marginBottom: '35px', flex: 1 }}>
                        {props.children}
                    </div>
                </div>
                <div className="mt-3" >
                    <Footer />
                </div>

            </div>

        </div>
    )
}

export default Layout;