import configurations from "../../configurations";

export const commomPost = async (suburl, userData) => {
    let data = {
        method: 'POST',
        body: JSON.stringify(userData),
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    };
    const url = configurations.enrollmentBaseUrl + suburl;
    return fetch(url, data)
        .then((response) => {
            if (response.headers.get('content-type').match(/application\/json/)) {
                return response.json();
            }
            return response;

        })
        .catch((e) => {
            //DeviceEventEmitter.emit('API')
        })
}


export const getTransactionIdValue = (value) => {
    let transactionId = value;
    let transactionLimit = 13;
    if (value.length >= transactionLimit) {
        transactionId = transactionId.substr(1)
    }
    let remainingLen = transactionLimit - value.length;
    if (remainingLen <= 0) {
        remainingLen = transactionLimit - transactionId.length;
    }
    // for (let i = 0; i < remainingLen; i++) {
    //     transactionId = '0' + transactionId
    // }
    return transactionId;
}

export const getNotesDate = () => {
    let hours = new Date().getHours();
    let ampm = hours >= 12 ? 'pm' : 'am';
    let str = new Date().getFullYear() - 2000;
    let month = new Date().getMonth() + 1;
    let month1 = '' + month
    let day = '' + new Date().getDate();

    if (month1.length < 2)
        month1 = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    let date = month1 + '/' + day + '/' + str + ' ' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds() + ' ' + ampm;

    return date;

}

export const isPaymentUser = () => {
    let user = localStorage.getItem("email")
    const paymentAdmins = ["dadra.morris@carynhealth.com", "danny.cuellar@carynhealth.com", "bryant.palacios@carynhealth.com", "barniz.beamon@carynhealth.com", "andrea.cuellar@carynhealth.com", "camila.burdette@carynhealth.com", "scott.thomas@carynhealth.com", "Allison.Spade@carynhealth.com", "Lina.Velasquez@carynhealth.com", "Madally.Villatoro@carynhealth.com", "paola.moreno@carynhealth.com"];
    if (paymentAdmins.includes(user)) {
        return true;
    } else {
        return false;
    }
}
