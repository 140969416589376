import React, { Component } from 'react';
import Sample from '../Commonscreens/CommonTextField.js';
import customStyle from '../../Assets/CSS/stylesheet_UHS';
import { withStyles,createStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {commomPost} from '../Services/CommonServices';
import Grid from '@material-ui/core/Grid';
import Footer from '../Commonscreens/Footer';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Loader from '../Commonscreens/loader';

const DoneBtn = withStyles(
    customStyle.copyBtn
)(Button);

const GenBtn = withStyles(
    customStyle.genrateBtn
)(Button);

const GenBtn1 = withStyles(
    customStyle.genrateBtn1
)(Button);


class UrlEncoding extends Component {
    constructor(props){
        super(props);
        this.state={
            bid:'',
            cid:'',
            aid:'',
            url:'',
            genrate:true,
            loading:false,
            baseUrl:''
        }
    }

    setValue(name,value){
        if(value !== undefined && value !== null){
            if(name === 'brokerid'){
                this.setState({bid:value},()=>{
                    this.checkVal()
                })
            }else if(name === 'associationid'){
                this.setState({aid:value},()=>{
                    this.checkVal()
                })
            }else if(name === 'clientid'){
                this.setState({cid:value},()=>{
                    this.checkVal()
                })
            }else{
                this.setState({baseUrl:value},()=>{
                    this.checkVal()
                })
            }
        }
    }

    checkVal(){
        if(this.state.cid !== '' && this.state.bid !== '' && this.state.aid !== '' && this.state.baseUrl !== ''){
            this.setState({genrate:false})
        }else{
            this.setState({genrate:true})
        }
    }

    copy(){
        this.state.url.select();
        document.execCommand("copy")
    }

    generateUrl(val){
        this.setState({loading:true})
        var obj={
            "clientId":this.state.cid,
            "associationId":this.state.aid,
            "brokerId":this.state.bid
        }
        commomPost('/encrypt/encryptData',obj)
            .then(res=>{
                if(res && res.response){
                    this.setState({url:this.state.baseUrl+'#state='+res.response,loading:false})
                }
            })
    }

    handleBack = () =>{
        this.props.onClick();
    }

    render(){
        return (
            <div style={{ marginLeft: '20%' }}>
            {/* ,backgroundColor:'#bfbfbf', height:'550px',*/}
                {
                    this.state.loading &&
                    <Loader></Loader>
                }
                <div style={{ padding: '2%', width: '830px', marginTop: '0%' }}>
                <div style={customStyle.HomeContainer}>
                                <div style={{ backgroundColor: 'rgb(51,175,176)', dispaly: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', width: '100%', padding: '15px', fontWeight: 'bold' }} >Encode URL</div>
                    <div style={customStyle.HomeMAinChild}>
                        <div style={customStyle.HomeTextContiner}>
                            <div style={{width: '440px', margin:'10px'}}>
                                <Sample setval={this.setValue.bind(this)} reqFlag={true} name={'brokerid'} label={'Broker Id'} value={this.state.bid} disable={false} style={customStyle.textFieldWrpAgent} length={5}  fieldType={'inch'} errMsg={'Enter Broker Id'} helperMsg={'Broker Id Required'}  parentDetails={{name:'inches'}}></Sample>
                            </div>
                            </div>
                        <div style={customStyle.HomeTextContiner}>
                            <div style={{width: '440px', margin:'10px'}}>
                                <Sample setval={this.setValue.bind(this)} reqFlag={true} name={'associationid'} label={'Association Id'} value={this.state.aid} disable={false} style={customStyle.textFieldWrpAgent} length={5}  fieldType={'inch'} errMsg={'Enter Association Id'} helperMsg={'Association Id Required'}  parentDetails={{name:'inches'}}></Sample>
                            </div>
                            </div>
                        <div style={customStyle.HomeTextContiner}>
                            <div style={{width: '440px', margin:'10px'}}>
                                <Sample setval={this.setValue.bind(this)} reqFlag={true} name={'clientid'} label={'Client Id'} value={this.state.cid} disable={false} style={customStyle.textFieldWrpAgent} length={5}  fieldType={'inch'} errMsg={'Enter Client Id'} helperMsg={'Client Id Required'}  parentDetails={{name:'inches'}}></Sample>
                            </div>
                        </div>
                        <div style={customStyle.HomeTextContiner}>
                        <div style={{width: '440px', margin:'10px'}}>
                            <Sample setval={this.setValue.bind(this)} reqFlag={true} name={'baseUrl'} label={'Enter your base url here'} value={this.state.baseUrl} disable={false} style={customStyle.textFieldWrpAgent} length={5}  fieldType={'inch'} errMsg={'Enter base url'} helperMsg={'Base url Required'}  parentDetails={{name:'burl'}}></Sample>
                            </div>
                        </div>
                        <div style={{ margin : "10px"}}>
                                <GenBtn1 variant="contained"  color="primary" onClick={()=>this.generateUrl('login')}  disabled={this.state.genrate}>Generate URL</GenBtn1>
                            </div>
                        <div style={{marginTop:'2%'}}>
                            <table>
                                <tr>
                                <td>
                            <div style={customStyle.HomeLoginUrlWrp}>{this.state.url}</div>
                            
                                </td>
                                <td>
                            <div style={{textAlign:'center'}}
                            >
                            </div>
                            </td>
                            
                        </tr>
                        </table>
                        </div>
                        
                        <div style={customStyle.urngenBtnwrp}>
                        <CopyToClipboard text={this.state.url}>
                                    <DoneBtn variant="contained"  color="primary" style={{marginLeft:'10px'}} disabled={this.state.url === ''} onClick={()=> this.copy}>COPY</DoneBtn>
                          </CopyToClipboard>
                            <div style={{ margin : "10px"}}>
                                <GenBtn  variant="contained" style={{ width : "150px"}} color="primary" onClick={()=>this.handleBack()}>Back To Home</GenBtn>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <Footer /> */}
                {/* <div style={{marginTop:'-46px'}}>
                    <Footer />
                </div> */}
            </div>
            </div>
        );
    }
}
export default UrlEncoding;