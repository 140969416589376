import React, { Component } from 'react';
import MUIDataTable from "mui-datatables";
import configurations from "../../configurations";
import {createMuiTheme,withStyles,MuiThemeProvider} from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import { isEmpty, includes, keysIn, groupBy } from "lodash";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import HomeIcon from '@material-ui/icons/Home';
import customStyleUHS from '../../Assets/CSS/stylesheet';
import Loader from "../Commonscreens/loader";
import {
    FormControl,
    InputLabel,
    Select,
    Typography,
    TextField
} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import RefreshIcon from '@material-ui/icons/Refresh';
import Fab from "@material-ui/core/Fab";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import axios from 'axios';
import Chip from '@material-ui/core/Chip';
import { maxWidth } from '@material-ui/system';
const CrudButton = withStyles(
    customStyleUHS.crudBtnAgent,
)(Fab);

var formData = new FormData();
formData.append("draw","1");
formData.append("start","0");
formData.append("length","10");

formData.append("columns[0].data","id");
formData.append("columns[0].name","");
formData.append("columns[0].searchable","true");
formData.append("columns[0].orderable","true");
formData.append("columns[0].search.regex","false");
formData.append("columns[0].search.value","");

formData.append("columns[1].data","user");
formData.append("columns[1].name","");
formData.append("columns[1].searchable","true");
formData.append("columns[1].orderable","true");
formData.append("columns[1].search.regex","false");
formData.append("columns[1].search.value","");

formData.append("columns[2].data","createdDate");
formData.append("columns[2].name","");
formData.append("columns[2].searchable","true");
formData.append("columns[2].orderable","true");
formData.append("columns[2].search.regex","false");
formData.append("columns[2].search.value","");

formData.append("columns[3].data","eventName");
formData.append("columns[3].name","");
formData.append("columns[3].searchable","true");
formData.append("columns[3].orderable","true");
formData.append("columns[3].search.regex","false");
formData.append("columns[3].search.value","");

formData.append("columns[4].data","eventType");
formData.append("columns[4].name","");
formData.append("columns[4].searchable","true");
formData.append("columns[4].orderable","true");
formData.append("columns[4].search.regex","false");
formData.append("columns[4].search.value","");

formData.append("columns[5].data","eventData");
formData.append("columns[5].name","");
formData.append("columns[5].searchable","true");
formData.append("columns[5].orderable","true");
formData.append("columns[5].search.regex","false");
formData.append("columns[5].search.value","");

formData.append("columns[6].data","referenceTransactionId");
formData.append("columns[6].name","");
formData.append("columns[6].searchable","true");
formData.append("columns[6].orderable","true");
formData.append("columns[6].search.regex","false");
formData.append("columns[6].search.value","");

formData.append("columns[7].data","response");
formData.append("columns[7].name","");
formData.append("columns[7].searchable","true");
formData.append("columns[7].orderable","true");
formData.append("columns[7].search.regex","false");
formData.append("columns[7].search.value","");

formData.append("columns[8].data","newTransactionId");
formData.append("columns[8].name","");
formData.append("columns[8].searchable","true");
formData.append("columns[8].orderable","true");
formData.append("columns[8].search.regex","false");
formData.append("columns[8].search.value","");

formData.append("columns[9].data","status");
formData.append("columns[9].name","");
formData.append("columns[9].searchable","true");
formData.append("columns[9].orderable","true");
formData.append("columns[9].search.regex","false");
formData.append("columns[9].search.value","");


formData.append("order[0].column","0");
formData.append("order[0].dir","desc");
formData.append("search.regex","false");
formData.append("search.value","");

let requestOptions = {
    method: 'POST',
    body: formData,
};

const GenBtn = withStyles(
    customStyleUHS.genrateBtn
)(Button);

let theData = [];
class AuditLog extends Component{
    state = {
        loaderShow: false,
        data: [],
        count:10,
        rowsPerPage:10,
        searchTypeValue:'',
        searchShow:true,
        searchText:'',
        statusValue:'',
        targetDate:null,
        birthDtFocus:false
      };

  componentDidMount() {
    this.setState({
        loaderShow : true
    });
    this.getDetails(requestOptions);
}



getDetails = (requestOptions) => {
   console.log("requestOptions:::",requestOptions); 
  formData.set("start", "0");
  formData.set("length", this.state.rowsPerPage.toString());

    fetch(configurations.auditBaseUrl + '/transaction/getAuditLogs/', requestOptions)
    .then(response => response.json())
    .then(result => {
        console.log('-------------------Data----------------------')
        
        let res=result.response.data.map(data=>{return data.response})
        // let stat=res.map(st=>{return st.result.map(dd=>{return dd})})
        let stat=Object.keys(res).map((k,i)=>( res[k]))        
        console.log(stat)
      if (result.response && result.response.data) {
          this.setState({ data: result.response.data ,count:result.response.recordsFiltered,loaderShow:false});
      }
      
  });
 
}

// ======================Filter code=============================

  handleFilterSubmit = applyFilters => {
    let filterList = applyFilters();
    console.log('===============')
    console.log(filterList) 

    for(let i=0; i<filterList.length; i++){
        console.log('===============')
        console.log(filterList[0][i])
        
        if(i==0){
            
            let strDate = filterList[i][0];
            if(strDate !== null && strDate !== "" && strDate !== undefined ){
                console.log('strdate::',strDate)
                strDate = moment(filterList[i][0], "MMMM DD, YYYY").format("YYYY-MM-DD");
                console.log('strdate::',strDate)
                formData.set("columns[2].search.value",strDate);
            }else{
                console.log('strdate::',strDate)
                formData.set("columns[2].search.value",'');
            // }
        } 
    }
    }
    this.getDetails(requestOptions);
  };

// ==========================Search==============================

handleChageSearchType(event){
    console.log('================ handleChageSearchType ===========================');
    console.log(event.target.value);
    let value = event.target.value
    
    if(value === ''|| value === null ){
        this.setState({searchShow:true, searchText : '',statusValue:''},() => {
            console.log('============== searchText ===============');
            console.log(this.state.searchText);
            this.searchRecord();
        });

    }
    else{
        if(this.state.searchTypeValue === '0'){
            formData.set("columns[1].search.value","");
        } else if(this.state.searchTypeValue === '1'){
            formData.set("columns[9].search.value","");
        } else if(this.state.searchTypeValue === '2'){
            formData.set("columns[2].search.value","");
        }  
        this.setState({searchTypeValue:value, searchShow:false, searchValue:'',searchText : ''}, () =>{
            console.log('============== searchText ===============');
            console.log(this.state.searchText);
            this.searchRecord();
        });
    }

}


// ===========================================================================================

searchRecord = () =>{
    this.setState({
        loaderShow : true
    });
    if(this.state.searchTypeValue === '0'){             //-------------name search ----------------
        this.state.targetDate=null
        formData.set("start", "0");
        formData.set("columns[1].search.value",this.state.searchText);
        fetch(configurations.auditBaseUrl + '/transaction/getAuditLogs/',requestOptions)
                .then(response => response.json())
                .then(data => {
                    if(data.response && data.response.data){
                        this.setState({data:data.response.data,count:data.response.recordsFiltered,targateDate:null,statusValue:'',loaderShow:false})
                    } else {
                        this.setState({loaderShow : false});
                    }

                });
    }
                if(this.state.searchTypeValue === '1'){     
                    this.state.targetDate=null        //-----------------status search-----------------
                    formData.set("start", "0");
                    if(this.state.searchText === '0' || this.state.searchText === 'Success'){
                        formData.set("columns[9].search.value",'SUCCESS');
                    }else if(this.state.searchText === '1'||this.state.searchText === 'Fail'){
                        formData.set("columns[9].search.value",'FAIL');
                    }        
                    fetch(configurations.auditBaseUrl + '/transaction/getAuditLogs/',requestOptions)
                            .then(response => response.json())
                            .then(data => {
                                if(data.response && data.response.data){
                                    this.setState({data:data.response.data,count:data.response.recordsFiltered,loaderShow:false})
                                } else {
                                    this.setState({loaderShow : false});
                                }

                            });
                }
                if(this.state.searchTypeValue === '2'){    //-------------Date search--------------------
                    formData.set("start", "0");
                        formData.set("columns[2].search.value",this.state.searchText);
                    
                    fetch(configurations.auditBaseUrl + '/transaction/getAuditLogs/',requestOptions)
                            .then(response => response.json())
                            .then(data => {
                                if(data.response && data.response.data){
                                    this.setState({data:data.response.data,count:data.response.recordsFiltered,statusValue:'',loaderShow:false})
                                } else {
                                    this.setState({loaderShow : false});
                                }

                            });
                }
}

setSearch(event){
    let value = event.target.value;
    this.setState({
        searchText : value
    },() => {
        if(this.state.searchText === "" || this.state.searchText === null ){
            this.searchRecord();
        }
    });     
}


statusChange=(event)=>{
    console.log(event.target.value);
    this.setState({statusValue: event.target.value, searchText :event.target.value})
}


handleDate(event){
    
    this.setState({
        targetDate:moment().format('YYYY')+'-'+moment(event).format('MM')+'-'+moment(event).format('DD'),
        searchText:moment(event).format('YYYY')+'-'+moment(event).format('MM')+'-'+moment(event).format('DD'),
        
    });
}


//   ==============Change page =====================

    changePage=(page,rows)=>{
        this.setState({
            loaderShow : true
        });
        if (page !== undefined && rows) {
            let nextPage = (page * rows)
            formData.set("start", nextPage.toString());
            formData.set("length",rows.toString());
            fetch(configurations.auditBaseUrl + '/transaction/getAuditLogs/' , requestOptions)
                .then(response => response.json())
                .then(data => {
                    if (data.response && data.response.data) {
                        this.setState({data: data.response.data,rowsPerPage:rows,loaderShow: false})
                    }else{
                        this.setState({loaderShow: false})
                    }
                });
        }
    }

    // =============== Table Theme========================


    getMuiTheme = () => createMuiTheme({
        overrides: {
            MUIDataTableBodyCell: {
                root: {
                   // paddingLeft: '7px ',
                   paddingBottom:'5px',
                //    paddingRight:'23px',
                   paddingTop:'5px',
                   fontSize:'14px',
                   color:'#333333',
                    width:'max-content'
                },
                responsiveBase:{
                    // root:{
                    padding:'30px'
                    // }
                },
                emptyTitle:{
                    textAlign:'center',
                    // ,width:'0px',
                    marginLeft:'450px'
                }
            },
            MUIDataTableBody:{emptyTitle:{
                textAlign:'center',marginLeft:'450px'
            }},
            MuiTableRow: {
                root: {
                  '&$selected': {
                    backgroundColor: '#ebf2ff'
                  }
                }
              },
              MUIDataTable:{responsiveBase:{margin:'23px'}},
              
            MuiTypography:{h6:{fontWeight:'bold',marginBottom:'-28px',marginLeft:'-70%',marginRight:'50px'}},
            MuiToolbar:{gutters:{paddingLeft:'5px'},regular:{minHeight:'0px'}},
            MuiTableCell:{root:{borderBottom:'1px solid rgb(0,0,0)',padding:'8px'},footer:{borderBottom:'none'}},
            MuiDataTablePagination:{borderBottom:'none'},
            MUIDataTableHeadCell: {
                root: {
                    background: 'blue',
                    fontWeight:'bold',
                    paddingLeft:'10px',
                    padding:'0px'
                },
                MUIDataTable: {
                    responsiveScroll: {
                        maxHeight: '380px'

                    },
                    
                  },
                
                
            },
        }
    })

    handleBack = () => {
        this.setState({loaderShow:true,searchTypeValue:'',statusValue:'',searchText:''})
        formData.set("start", "0");
		formData.set("columns[2].search.value",'');
		formData.set("columns[9].search.value",'');
		 formData.set("columns[1].search.value",'');
        this.props.onClick();
    }
    refreshTable=()=>{
        this.setState({loaderShow:true,searchTypeValue:'',statusValue:'',searchText:''})
        formData.set("start", "0");
		formData.set("columns[2].search.value",'');
		formData.set("columns[9].search.value",'');
		 formData.set("columns[1].search.value",'');
        this.getDetails(requestOptions);
    }
    
    render(){
        const columns=[
            {label:'Date',name:'createdDate', options: {
               filter: true,
            // filterType: 'multiselect',
               sort: false,
               customBodyRender: value =>
                 moment(new Date(value)).format('MMMM DD, YYYY')
               
             }},
            {label:'User Name',name:'user',options:{filter:false,sort: false}},
            {label:'Event Type',name:'eventType',options:{filter:false,sort: false}},
            {label:'Auth Message',name:'status',options:{filter:false,sort: false}},
            {label:'Reference Tansaction Id',name:'referenceTransactionId',options:{filter:false,sort: false}},
            {label:'New Transaction Id',name:'newTransactionId',options:{filter:false,sort: false}},
            {label:'Response',name:'response',options:{filter:false,sort: false}},
            {label:'Event Data',name:'eventData',options:{filter:false,sort: false}},
            
        ]
        const options ={
            filter: false, 
            filterType: 'dropdown',
            responsive: 'standard',
            serverSide: true,            
            selectableRowsHeader:false,
            count:this.state.count,
            selectableRows:false,
            rowsPerPage:this.state.rowsPerPage,
            search:false,
            confirmFilters: true,    
            viewColumns:false,
            print:false,   
            
            customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
              return (
                <div style={{ marginTop: '40px' }}>
                  <button variant="contained" onClick={() => this.handleFilterSubmit(applyNewFilters)}>Apply Filters</button>
                </div>
              );
            },
                onFilterConfirm: (filterList) => {
                    console.log('onFilterConfirm');
                    console.log(filterList);
                },

                onFilterDialogOpen: () => {
                    console.log('filter dialog opened');
                },
                onFilterDialogClose: () => {
                    console.log('filter dialog closed');
                },
                onFilterChange: (column, filterList, type) => {
                    // if (type === 'chip') {
                    var newFilters = () => (filterList);
                    console.log('updating filters via chip',filterList);
                    this.handleFilterSubmit(newFilters);
                    // }
                },
                onTableChange: (action, tableState) => {
                   switch (action) {
                       case 'changePage':
                           this.changePage(tableState.page,tableState.rowsPerPage);
                           break;
                       case 'changeRowsPerPage':
                           this.changePage(tableState.page,tableState.rowsPerPage);
                           break;
                       default:
                           break;
                   }
               },
               customToolbar: () => {
                   return<></>
               }
    };
        return(
            <div  style={{width:'1125px',marginTop:'25px'}}>
                {
                        this.state.loaderShow ? <Loader></Loader> : ''
                    }
                <MuiThemeProvider theme={this.getMuiTheme()}>
                <MUIDataTable
                    title={<div style={{marginTop:'4%',width:'120%',display:'flex',marginLeft:'15%',marginBottom:'10px'}}>
                        
                        <span style={{fontSize:'18px',fontWeight:'bold',marginLeft:'-108px',marginRight:'26px',marginTop:'6px'}}>Audit Logs</span>
                
                    {/* <div> */}
                    <FormControl style={{width:'160px',marginTop:'-14px',marginLeft:'17px'}}>
                        <InputLabel  style={{textAlign:'left'}}
                        >Search By</InputLabel>
                        <Select
                            label="Search By"
                            name="name"
                            style={{textAlign:'left'}}
                            // style={customStyleUHS.searchByStyle}
                            value={this.state.searchTypeValue}
                            onChange={(event) =>this.handleChageSearchType(event)}
                        >
                            <MenuItem value='0' >User Name</MenuItem>
                            <MenuItem value='1' >Status</MenuItem>
                            <MenuItem value='2' >Date</MenuItem>
                        </Select>
                    </FormControl>

                                {this.state.searchTypeValue === '2'?
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                //    required
                                   // error=''
                                    autoComplete='off'
                                    margin="none"
                                    label="Select Date"
                                    format="MMMM dd,yyyy"
                                    value={this.state.targetDate}
                                    onFocus={e => e.target.blur()}
                                    style={{marginLeft:'21px',marginTop:'-20px'}}
                                    // helperText={this.searchRecord}
                                    inputProps={{style: {fontSize:'12.8px',fontfamily: 'Roboto',marginTop:'10px',
                                                //    paddingLeft:'7px'
                                                }}}
                                    InputLabelProps={{style:{paddingRight:10,paddingTop:12,color: 'grey'}}}
                                    onChange={(event)=>this.handleDate(event)}
                                    variant="filled"
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    views={["year", "month", "date"]}
                                openTo="year"
                                    minDate={new Date('01/01/2020')}
                                />
                            
                            </MuiPickersUtilsProvider>
                            :this.state.searchTypeValue === '1'?

                                <FormControl style={{width:'160px',marginTop:'-14px',marginLeft:'33px'}} >
                            <InputLabel id="demo-simple-select-label" style={{}}
                            >Select status</InputLabel>
                            <Select name="name"  style={{textAlign:'left'}} value={this.state.statusValue} onChange={(event) =>this.statusChange(event)}>
                                <MenuItem value='0'>Success</MenuItem>
                                <MenuItem value='1' >Fail</MenuItem>
                            </Select>
                        </FormControl> 
                        :
                                        <TextField 
                                        style={{marginLeft:'28px',marginTop:'3px'}}
                                            value={this.state.searchText}
                                            disabled={this.state.searchShow}
                                            onChange={(event)=>this.setSearch(event)}></TextField>       
                                }

                    <CrudButton color="secodary" style={{boxShadow:'none',height:'40px',width:'40px',marginLeft:'13px'}} aria-label="search" disabled={this.state.searchText==''||this.state.targetDate==''} onClick={()=>this.searchRecord()} >
                    <SearchIcon />
                </CrudButton>
                <Tooltip title="Refresh" aria-label="refresh">
                <CrudButton style={{boxShadow:'none',width:'40px',height:'40px',marginLeft:'10px'}} onClick={this.refreshTable}><RefreshIcon /></CrudButton>
                </Tooltip>
                <Tooltip title="Back To Home" aria-label="home">
                <CrudButton style={{boxShadow:'none',width:'40px',height:'40px',marginLeft:'10px'}} onClick={()=>this.handleBack()}><HomeIcon /></CrudButton>
                </Tooltip>
                </div>
                               
                
                
                
                }
                    data={this.state.data}
                    columns={columns}
                    options={options}
                  />
                  </MuiThemeProvider>
                  
            </div>
        )
    }
}
export default AuditLog;